<template>
  <IOSSNumbersEditingSection
      :IOSSNumber="IOSSNumber"
      :countries="countries"
      @save="save"
  />
</template>

<script>
  import {IOSSNumbers} from "../models/IOSSNumbers";
  import {IOSSNumbersMixin} from "../../../../../mixins/IOSSNumbersMixins/IOSSNumbersMixin";
  import IOSSNumbersEditingSection from "./IOSSNumbersEditingSection/IOSSNumbersEditingSection";

  export default {
    name: "IOSSNumbersCreation",

    components:{
      IOSSNumbersEditingSection,
    },

    mixins: [IOSSNumbersMixin],

    data() {
      return {
        IOSSNumber: new IOSSNumbers(),
        countries: [],
      }
    },

    mounted() {
      this.initIOSSNumbersEdit()
    },

    methods: {
      initIOSSNumbersEdit() {
        this.$store.dispatch('fetchCountries').then(() => {
          this.countries = this._.toArray(this.$store.getters.getCountries)

          this.IOSSNumber.setId(this.$route.params.id)

          this.$store.dispatch('getIOSSNumbers', this.IOSSNumber.getId()).then((response) => {
            let item = this.getRespData(response)
            console.log(item);
            this.IOSSNumber.setItem(item, this)
          }).catch(error => this.createErrorLog(error))
        })
      },

    }

  }
</script>

<style scoped>

</style>
