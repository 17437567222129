import { render, staticRenderFns } from "./IOSSNumbersEditing.vue?vue&type=template&id=38df642c&scoped=true"
import script from "./IOSSNumbersEditing.vue?vue&type=script&lang=js"
export * from "./IOSSNumbersEditing.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38df642c",
  null
  
)

export default component.exports